<template>
  <CCardBody>
    <!-- Filters -->
    <CCard class="filters" accent-color="warning">
      <CCardHeader
        role="button"
        class="text-warning shadow-none card-header"
        @click="resetFilters"
      >
        <strong class="m-0"><CIcon :name="`cil-filter${$store.state.filterShow ? '-x' : ''}`" /> Filters</strong>
        <div class="card-header-actions">
          <CIcon :name="`cil-chevron-${$store.state.filterShow ? 'bottom' : 'top'}`"/>
        </div>
      </CCardHeader>

      <CCollapse :show="$store.state.filterShow">
        <CCardBody class="p-2">
          <div class="row">
            <!-- Hub Filter -->
            <div class="col-lg mb-2 mb-lg-0">
              <v-select
                class="v-select-filter"
                placeholder="Hubs.."
                label="name"
                v-model="selectedHubs"
                :options="hubs"
                :searchable="false"
                :reduce="hub => hub.id"
                multiple
                @input="inputFilter"
              >
                <template v-slot:option="hub">
                  <strong>{{ hub.zip }}</strong> -
                  <span>{{ hub.name }} </span>
                </template>
              </v-select>
            </div>

            <!-- Search -->
            <div class="col-lg mb-0 pl-lg-0">
              <CInput
                class="mb-0 search"
                type="search"
                placeholder="Search for something else ..."
                v-model="search"
                @input="searchFilter"
              />
            </div>
          </div>
        </CCardBody>
      </CCollapse>
    </CCard>

    <CDataTable
      striped
      hover
      :items="loadedItems"
      :fields="fields"
      :sorter="{ external: true, resetable: true }"
      :itemsPerPageSelect="{
        external: true,
        values: [5, 15, 25, 50, 100, 250, 500],
      }"
      :items-per-page.sync="itemsPerPage"
      :loading="loading"
      :noItemsView="{
        noResults: 'No filtering results are available!',
        noItems: 'No courier found!',
      }"
      @update:sorter-value="sorterValue"
      @pagination-change="paginationChange"
    >
      <!-- Actions -->
      <template #actions="{ item, index }">
        <td>
          <CButton
            :to="{ name: 'Edit Courier', params: { id: item.id } }"
            v-c-tooltip="{
              content: 'Edit',
              placement: 'top',
            }"
            size="sm"
            color="info"
            variant="ghost"
            shape="pill"
          >
            <CIcon name="cil-align-left" />
          </CButton>

          <template v-if="item.is_deletable === true">
            <CSpinner v-if="item.deleting" size="sm" color="danger" class="ml-3" />
            <CButton
              v-else
              class="ml-1"
              size="sm"
              color="danger"
              variant="ghost"
              shape="pill"
              @click="deleteItem(item, index)"
            >
              <CIcon name="cil-trash" />
            </CButton>
          </template>
        </td>
      </template>

      <!-- ID -->
      <template #id="{item}">
        <td>
          #{{ item?.id }}
        </td>
      </template>

      <!-- User Icon -->
      <template #image_icon="{item}">
        <td>
          <CImg
            :src="item.image_icon || item.gravatar"
            class="c-avatar-img"
            style="width: 36px; height: 36px"
            placeholderColor="lightgray"
          />
        </td>
      </template>

      <!-- Name -->
      <template #first_name="{item}">
        <td>
          <span v-if="item.settings?.is_dispatcher" class="dot bg-secondary" v-c-tooltip="'Dispatcher'">👔</span>
          <span v-else-if="item.settings?.is_subordinate" class="dot bg-secondary" v-c-tooltip="`#${item.detail.parent_id}'s subordinate`">👷</span>
          {{ item.first_name }}
        </td>
      </template>

      <!-- Contact -->
      <template #contact="{item}">
        <td>
          <div><CIcon name="cil-at" class="mr-1" />{{ item.email }}</div>
          <div><CIcon name="cil-phone" class="mr-1" />{{ item.mobile }}</div>
        </td>
      </template>


      <!-- Vehicle -->
      <template #vehicle="{item}">
        <td>
          <div v-if="item.vehicle" class="d-flex align-items-center flex-gap-2">
            <MIcon :tag="item.vehicle.mode" />
            <span class="text-uppercase">{{ item.vehicle.license_plate }}</span>
          </div>
        </td>
      </template>

      <!-- Hubs -->
      <template #hubs="{item}">
        <td class="p-0">
          <CIcon v-if="item._classes.includes('table-warning')" name="cil-warning" size="lg" />
          <CBadge class="mx-1" color="secondary" v-for="hub in item.hubs">{{ hub.name }}</CBadge>
        </td>
      </template>

      <!-- Is Working -->
      <template #is_working="{ item, index }">
        <td class="text-center pr-4">
          <template v-if="item.status?.is_working_now">
            <CLink v-if="!item.status?.on_break" :to="{ name: 'Edit Courier', params: { id: item.id }, query: { tab: 1 } }">
              <span class="dot bg-success"></span>
            </CLink>

            <!-- On break -->
            <template v-else>
              <CSpinner v-if="item.updating" size="sm" color="warning" class="mb-1" />
              <span v-else class="dot bg-warning" role="button" @click="forceEndBreak(item, index)"></span>
            </template>
          </template>

          <span v-else class="dot bg-danger"></span>
        </td>
      </template>

      <!-- On Order -->
      <template #on_order="{ item }">
        <td class="text-center pr-4">
          <div v-if="item.status?.on_order" class="vertical-center justify-content-center separate-items">
            <div v-for="(order, index) in item.active_orders" :key="index" class="vertical-center justify-content-center flex-gap-1">
              <CLink :to="{ name: 'Order Details', params: { id: order.id } }"
                v-c-tooltip="{ content: `o#${order.id}`, placement: 'top' }"
                style="height: 20px;"
              >
                <span class="dot bg-success"></span>
              </CLink>

              <CLink v-if="order.revocable"
                class="align-self-baseline"
                v-c-tooltip="{ content: 'Transfer or revoke order', placement: 'top' }"
                @click="transferOrRevokeOrder(order)"
              >
                <CIcon name="cil-transfer" />
              </CLink>
            </div>
          </div>
          <span v-else class="dot"></span>
        </td>
      </template>

      <!-- Created -->
      <template #created_at="{item}">
        <td>{{ moment(item.detail?.created_at).format('LL') }}</td>
      </template>

    </CDataTable>

    <!-- Actions -->
    <CCard class="actions sticky-bottom">
      <CCardBody class="p-2">
        <div class="d-flex flex-wrap align-items-center" style="gap: 0.75rem">
          <div>
            <h5 class="mt-1">Total: <span class="d-inline count bg-primary pb-1">{{ total }}</span></h5>
          </div>

          <div v-if="pages > 1" class="ml-auto">
            <CPagination
              align="center"
              :dots='false'
              :pages="pages"
              :active-page.sync="activePage"
            />
          </div>
        </div>
      </CCardBody>
    </CCard>

    <mc-spinner :opacity="0.8" v-show="loading" :mtop="(32 + mcSpinnerMarginTop) + 'px'" />

    <TransferOrRevokeOrder
      :courierId="selectedOrder?.courier_id"
      :order="$globalFunc.extractKeys(selectedOrder, ['id', 'roDesiredDelivireyTime', 'roAproxDeliveryDateToCourier', 'roAddress', 'roCity', 'roPostal_code', 'roCountryId'])"
      :restaurant="$globalFunc.extractKeys(selectedOrder?.restaurant, ['id', 'restaurant_postcode'])"
      @transferred="getAllItems"
    />
  </CCardBody>
</template>

<script>
import { mapGetters } from "vuex";
import _debounce from 'lodash/debounce';
import TransferOrRevokeOrder from "@/components/Courier/TransferOrRevokeOrder";

export default {
  name: "BackendTable",
  components: {
    TransferOrRevokeOrder
  },
  data() {
    return {
      loadedItems: [],
      fields: [
        { key: "actions", label: "", sorter: false, _style: "min-width: 84px; width: 84px;" },
        { key: "id", label: "ID", _style: "min-width: 60px; width: 60px;" },
        { key: "image_icon", label: "", sorter: false,  _style: "min-width: 60px; width: 60px;" },
        { key: "first_name", label: "Name", sorter: true, _style: "min-width: 140px;" },
        { key: "contact", sorter: false, _style : "min-width: 200px;" },
        { key: "vehicle", sorter: false, _style : "min-width: 150px;" },
        { key: "hubs", label: "Hubs", sorter: false, _style: "min-width: 150px;" },
        { key: "is_working", label: "Is Working", sorter: false,  _style: "min-width: 60px; width: 60px; text-align: center;" },
        { key: "on_order", sorter: false,  _style: "min-width: 60px; width: 60px; text-align: center;" },
        { key: "created_at", sorter: false, _style: "min-width: 140px; width: 140px;" },
      ],

      activePage: 1,
      pages: 1,
      total: 0,
      items_per_page: null,
      orderBy: null,
      direction: null,
      search: null,

      hubs: [],
      selectedHubs: [],
      loading: false,

      selectedOrder: null,

      // Element Cover Spinner
      mcSpinnerMarginTop: 0,
    };
  },

  beforeMount() {
    this.searchFilter = _debounce(() => {
      this.getAllItems();
    }, 1000);
  },

  async mounted() {
    this.activePage =  this.queryPage;
    await Promise.all([this.getAllItems(), this.getHubs()]);

    window.onscroll = () => {
      this.mcSpinnerMarginTop = document.documentElement.scrollTop;
    }
  },

  watch: {
    reloadParams() {
      if(this.queryPage != this.activePage)
        this.$router.push({ name: 'Couriers', query: { page: this.activePage } });

      this.onTableChange();
    },
  },

  computed: {
    ...mapGetters(["errors"]),

    itemsPerPage: {
      get: function () { return this.items_per_page ? this.items_per_page : parseInt(process.env.VUE_APP_ITEMS_PER_PAGE) },
      set: function (newValue) { this.items_per_page = newValue }
    },
    reloadParams() { return [this.activePage]; },
    queryPage() { return parseInt(this.$route.query.page) || 1; },
  },

  methods: {
    async getAllItems() {
      this.loading = true;

      var url = new URL(this.$backend.COURIER.COURIERS.GET_ALL),
        params = {
          page: this.activePage,
          itemsPerPage: this.itemsPerPage,
          'order-by': this.orderBy,
          direction: this.direction,
          hubs: this.selectedHubs,
          search: this.search,
          'with-trashed': true
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== "" &&
          params[key].length !== 0
        )
          url.searchParams.append(key, params[key]);
      });

      await this.$axios.get(url)
        .then((response) => {
          return response.data;
        })
        .then((items) => {
          // Son sayfadan daha büyük bir queryPage çağrımı (?page=XXX) yapılırsa;
          if(items.current_page > items.last_page)
            this.activePage = 1;
          else {
            this.activePage = items.current_page;
            this.pages = items.last_page;
            this.total = items.total;

            this.loadedItems = items.data.map((el) => {
              return {
                ...el,
                updating: false,
                deleting: false,
                _classes: (!el.detail ? 'strikeout disable-item ' : (el.hubs.length === 0 ? 'table-warning ' : ''))
              };
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onTableChange() { // Active Page güncellendiğinde çağrılıyor.
      this.getAllItems();
    },
    paginationChange(value) { // Items Per Page güncellendiğinde çağrılıyor.
      this.itemsPerPage = value;
      this.getAllItems();
    },
    sorterValue(item) {
      this.orderBy = item.column;
      this.direction = item.column ? (item.asc ? "asc" : "desc") : null;
      this.getAllItems();
    },
    async deleteItem(item, index) {
      const confirm = await this.mSwal.fire({
        title: "Are you sure you want to delete?",
        text: "The courier will be deleted!",
        icon: "warning",
      });

      if (confirm.isConfirmed) {
        const deleteUrl = this.$backend.COURIER.COURIERS.DELETE.replace("{id}", item.id);
        item.deleting = true;

        try {
          const { data } = await this.$axios.delete(deleteUrl);
          // index !== -1 && this.$delete(this.loadedItems, index);
          item._classes = index !== -1 && 'strikeout disable-item';
          item.is_deletable = item.status.is_working_now = item.deleting = false;
          item.vehicle = null;
          this.$toast.success(data.data.message);
        } catch (error) {
          item.deleting = false;

          if (error.response) {
            this.$toast.error(`${error.response.data.message}`);
          } else if (error.request) {
            this.$toast.error('No response received from the server.');
          } else {
            this.$toast.error('An unexpected error occurred.');
          }
        } finally {
          this.$forceUpdate();
        }
      }
    },

    async getHubs() {
      await this.$axios
        .get(this.$backend.COURIER.HUBS.GET_LIST)
        .then(({ data }) => this.hubs = data)
        .catch(() => this.hubs = [])
    },

    // Filters
    inputFilter() {
      this.getAllItems();
    },
    resetFilters() {
      if (this.$store.state.filterShow) {
        this.selectedHubs = []
        this.search = "";
        this.getAllItems();
      }
      this.$store.commit('toggle', 'filterShow');
    },

    transferOrRevokeOrder(order) {
      this.selectedOrder = order;
      this.$store.state.transferOrRevokeOrderShow = true;
    },

    async forceEndBreak(courier, index) {
      const confirm = await this.mSwal.fire({
        title: "Are you sure you want to end the courier's break?",
        text: "The courier will be taken off break status.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, end break",
        cancelButtonText: "No, keep on break",
      });

      if (confirm.isConfirmed) {
        const endBreakUrl = this.$backend.COURIER.BREAKS.FORCE_END.replace("{id}", courier.id);
        courier.updating = true;

        try {
          const { data } = await this.$axios.post(endBreakUrl);

          if (data.success) {
            courier.status.on_break = false;
            this.$toast.success("The courier's break has been ended successfully.");
          }
        } catch (error) {
          const errorMessage = typeof this.errors === 'object'
            ? Object.values(this.errors).join()
            : this.errors;
          this.$toast.error(errorMessage);
        } finally {
          courier.updating = false;
          this.$forceUpdate();
        }
      }
    },
  },
};
</script>

<style>
.table th, .table td {
  padding: 0.5rem 0.25rem;
  vertical-align: middle;
}

.search input {
  font-size: 1rem !important;
}
</style>
